import User from '../user/User';

export default class PostViewerList {
  value: User[];

  constructor(
    value?: User[]
  ) {
    this.value = value === undefined ? [] : value;
  }

  static clone(source: PostViewerList): PostViewerList {
    return new PostViewerList(source.value.map((s) => User.clone(s)));
  }
}
