
import { Vue, Component } from 'vue-property-decorator';
import { NavigationGuardNext, Route } from 'vue-router';
import { Getter, Mutation, State } from 'vuex-class';
import InformationApi from '@/module/api/InformationApi';
import UserBaseInfo from '@/model/user/UserBaseInfo';
import CommentListView from '@/components/post/CommentListView.vue';
import PostDetail from '@/model/posts/PostDetail';
import FileInfo from '@/model/type/FileInfo';
import CommentPostView from '@/components/post/CommentPostView.vue';
import MessageShowBox from '@/components/post/MessageShowBox.vue';
import CommentDetail from '@/model/posts/comment/CommentDetail';
import BackButton from '@/components/Parts/BackButton.vue';
import CommentId from '@/model/posts/comment/CommentId';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave'
]);
@Component({
  components: {
    CommentListView,
    CommentPostView,
    MessageShowBox,
    BackButton
  }
})
export default class Information extends Vue {
  post:PostDetail = new PostDetail();
  files: FileInfo[] = []
  @State('loginUser') user!: UserBaseInfo;
  errorMessage:string = '';
  showDialog = false;

  @Getter('pageCache') getPageCache!: (key:string)=> any;
  @Mutation('commitPageCache') commitPageCache!: any;

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<Information>): void {
    next((vm: Information) => vm.initialize(to));
  }
  async initialize(to: Route): Promise<string> {
    try {
      const postId = Number(to.params.postId as string);
      const resPostDetail = await InformationApi.getPostDetail(postId);
      this.post = PostDetail.clone(resPostDetail.data);

      // 添付ファイル取得 & 既読
      const resFiles = await InformationApi.getSavedFiles(postId);
      this.files = FileInfo.clone(resFiles.data);
    } catch (err: any) {
      if (err.response?.status === 403) {
        this.errorMessage = err.response.data.detail;
      }
      // console.error(err);
    }

    return '';
  }

  hasError(): boolean {
    return this.errorMessage.length > 0;
  }
  hasData(): boolean {
    return !this.post.body.postId.isEmpty();
  }

  isMyPost(canShare: boolean): boolean {
    if (this.user.userId.value === this.post.body.registerId.value) return true;
    if (canShare) {
      // 管理者グループの場合、他の管理者グループの投稿は互いに編集可能
      if (this.user.priv.isAdminGroup && this.post.body.registerPriv.isAdminGroup) return true;
    }
    return false;
  }

  isShowViewer(): boolean {
    return this.post.viewers.value.length > 0;
  }

  copy(): void {
    this.$router.push(`/informationpost?copyfrom=${this.post.body.postId.value}`);
  }

  deletePost(): void {
    InformationApi.deletePost(this.post.body.postId)
      .then(() => {
        this.$root.$emit('event-show-snackbar', '情報投稿を削除しました');
        this.$router.go(-1);
      }).finally(() => {
        this.showDialog = false;
      });
  }

  rtnComment(event:any):void{
    const commentDetail = CommentDetail.create(event.commentId, this.user, event.commentMessage, this.post.body.postId);

    this.post.comment.push(commentDetail);
  }

  deleteComment(commentId:CommentId): void{
    this.post.comment = this.post.comment.filter((comment) => comment.body.commentId.value !== commentId.value);
  }
}
