import TypeJpDateTime from '../type/TypeJpDateTime';
import CommentDetail from './comment/CommentDetail';
import PostBody from './PostBody';
import PostViewerList from './PostViewerList';

export default class PostDetail {
  /** 投稿本体 */
  body: PostBody;
  /** コメント(親) */
  comment: CommentDetail[];

  /** 閲覧可能か */
  canRead: boolean;

  /** 投稿日時 */
  readOpenDate: TypeJpDateTime;

  /** 閲覧可能者 */
  viewers: PostViewerList = new PostViewerList();

  constructor(
    body?: PostBody,
    comment?: CommentDetail[],
    canRead?: boolean,
    readOpenDate?: TypeJpDateTime
  ) {
    this.body = (body === undefined ? new PostBody() : body);
    this.comment = (comment === undefined ? [] : comment);
    this.canRead = (canRead === undefined ? false : canRead);
    this.readOpenDate = (readOpenDate === undefined ? new TypeJpDateTime() : readOpenDate);
  }

  static clone(source: PostDetail): PostDetail {
    const result = new PostDetail();
    result.body = PostBody.clone(source.body);
    result.comment = CommentDetail.cloneList(source.comment);
    result.canRead = source.canRead;
    result.readOpenDate = TypeJpDateTime.clone(source.readOpenDate);
    result.viewers = PostViewerList.clone(source.viewers);
    return result;
  }
}
